import React from 'react'

function ElectronicsList() {
  return (
    <div className='header-secondSec-drop-cont'>
    <div  
    className= "header-secondSec-drop-items women" >  
      <div className='item'>
          <h3>Mobile & Accesssories</h3>
          <p>All Mobile</p>
          <p>Smartwatches</p>
          <p>Mobile Holders</p>
          <p>Mobile cases and covers</p>
      </div>
      <div className='item'>
          <h3>Appliance</h3>
          <p>Grooming</p>
          <p>All Appliance</p>
          <p>Home Appliance</p> 
      </div>  
      </div>
</div>
  )
}

export default ElectronicsList